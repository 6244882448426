const BASE_THEME = {
  navBackgroundHover: '#00386E',
  selectedLanguageDropdownColor: '#DEEBFF',
  focusedLanguageDropdownColor: '#F1F7FF',
  stopHighlightColor: '#5cc3ff',
  stopBladeIconHighlight: '#5bc4fc',
  activeOmniboxULLIColor: 'rgb(51, 122, 183)',
  newOmniboxULLIColor: '#dedede',
  trimetOrange: '#d14727',
  trimetHighlight: '#F7E886',
  originColor: '#6cb33f',
  selectedStopIconColor: '#6DB33F',
  destinationColor: '#e53333',
  activeButtonColor: '#6DB33F',
  activeButtonHover: '#609E38',
  activeButtonHover2: '#e0e0e0',
  activeButtonFocus: '#609E38',
  currentPositionColor: '#62bd19',
  clearCurrentPositionColor: '#969696',
  selectedStopColor: '#efecbe',
  activeOmniboxSpanColor: '#084c8d',
  readOnlyOpacity: 0.7,
  inactiveOpacity: 0.6,
  initialButtonOpacity: 0.65,
  realtimeNotificationColor: '#007494',
  vehicleTrackerHighlightColor: '#008ab0',
  vehicleTrackerLowlightColor: '#718ea9',
  vehicleTrackerBaseColor: 'transparent',
  tabActiveBorder: '#6cb33e',
  tabBackgroundColor: '#084c8d',
  tabColor: 'rgba(255,255,255,.6)',
  tabActiveColor: 'rgba(255,255,255,1)',
  routeDetailBadgeBorder: '#fff',
  routeStopFillColor: '#fff',
  routeStopStrokeColor: '#000',
  warningBackground: '#000000de',
  warningButtonHoverBackground: '#ffffff33',
  warningFontColor: '#fff',
  warningFontColorEmphasis: '#e7b008',
  textShadow: '0 1px 3px rgba(0,0,0,.25)',
  textShadowHover: '0 1px 4px rgba(0,0,0,.55)',
  closeShadow: 'drop-shadow(0 0 2px rgb(0 0 0 / 0.4))',
  closeShadowHover: 'drop-shadow(0 0 4px rgb(0 0 0 / 0.6))',
  buttonShadow: '0 1px 8px rgba(0,0,0,0.2)',
  buttonShadowHover: '0 0 5px rgba(0,0,0,0.2)',
  boxShadowPanel: '0 2px 10px rgba(0,0,0,0.4)',
  boxShadowInput: '0 2px 10px rgba(0,0,0,0.4)',
  boxShadowInputHover: '0 2px 10px rgba(0,0,0,0.6)',
  boxShadowButton: '0 0 5px rgba(0, 0, 0, 0.65)',
  boxShadowButtonHover: '0 0 5px rgba(0,0,0,0.85)',
  boxShadowCardHover: '0 0 5px rgba(0,0,0,0.3)',
  boxShadowTransition: 'box-shadow 0.125s ease-in-out',
  elementsTransition: 'all 0.25s ease',
  locationMarkerColor: '#3b7fc1',
  warnColor: '#ff8800',
  dangerColor: '#b50000',
  dangerColorHover: '#ba3d1b',
  baseAlertBackgroundColor: '#b03823',
  baseAlertHoverColor: '#c53f27',
  baseAlertBorderColor: '#a13320',
  // alerts > normal
  normalAlertIconFill: '#b03823',
  normalAlertBackgroundColor: '#FFFFCC',
  normalAlertHoverColor: '#575757',
  normalAlertBorderColor: '#c4c49d',
  normalAlertXColor: '#cecba2',
  normalViewText: '#000000A6',
  normalViewTextHover: '#ffffff',
  // alerts > priority
  priorityAlertBackgroundColor: '#b03823',
  priorityAlertHoverColor: '#7c2719',
  priorityAlertBorderColor: '#912e1d',
  priorityAlertXColor: '#912e1d',
  priorityViewTextHover: '#ffffff',
  // alerts > snow
  snowAlertBackgroundColor: '#4d84db',
  snowAlertHoverColor: '#376da3',
  snowAlertBorderColor: '#2468a8',
  snowAlertXColor: '#2468a8',
  // end alerts
  checkMarkColor: '#00a83e',
  notificationColor: '#d59f01',
  buttonRadiusSmall: '3px',
  buttonRadius: '5px',
  buttonRadiusLarge: '8px',
  inputRadius: '10em',
  focusVisible: '#6cb33e',
  menuFooterBg: '#000',
  tripTopSkeleton: '#e9e9e9',
  tripStepSkeleton: '#f4f4f4',
  tripTopSkeletonTop: '#d4d4d4',
  stopTopSkeletonMain: '#003d78',
  stopTopSkeletonHighlight: '#084c8d',
  blue_15: 'rgba(8, 76, 141, 0.15)',
  blue_25: 'rgba(8, 76, 141, 0.25)',
  blue_50: 'rgba(8, 76, 141, 0.5)',
  white_15: 'rgba(255, 255, 255, 0.15)',
  white_25: 'rgba(255, 255, 255, 0.25)',
  white_50: 'rgba(255, 255, 255, 0.5)',
  white: '#fff',
  languageDismissalXColor: '#939393'
};

export const LIGHT_THEME = {
  ...BASE_THEME,
  name: 'Light',
  mainColor: '#084c8d',
  secondaryColor: '#385979',
  tertiaryColor: '#e8eef4',
  backgroundColor: '#f5f5f5',
  secondaryBackgroundColor: '#ffffff',
  primaryFontColor: '#222',
  primaryFontColorReverse: '#fff',
  secondaryFontColor: '#575757',
  popperFontColor: '#707070',
  hoverColor: '#f5f5f5',
  hoverColor2: '#d8ecff',
  activeColor: '#dedede',
  hoverColorInverted: '#4573a1',
  activeColorInverted: '#28598a',
  focusColorInverted: '#5c8ab9',
  badgeBorderColor: 'transparent',
  successColor: '#008ab0',
  actionColor: '#4777a6',
  borderColor: '#efefef',
  buttonColor1: '#0080A3',
  buttonColor1Hover: '#007191',
  buttonColor2: '#efefef',
  buttonText1: '#fff',
  buttonText2: '#555',
  inverseSecondaryButtonBgColor: '#dedede',
  inverseSecondaryButtonBgColorHover: '#cecece',
  inverseBackgroundColor1: '#084c8d',
  inverseBackgroundColor2: '#0a559e',
  inverseBackgroundColor3: '#003d78',
  inverseBackgroundColor4: '#002B54',
  inverseFontColor1: '#ecf6ff',
  inverseFontColor2: '#A5C0D5',
  inverseFontColor3: '#084c8d',
  inverseButtonColor1: '#3F70A2',
  inverseButtonColor2: '#6896c4',
  inverseButtonColor3: '#86abd0',
  inactiveColor: '#808080',
  inputBorderColor: '#cecece',
  inputBorderColorFocus: '#000',
  inputBorderColorReverse: '#3F70A2',
  inputBorderColorFocusReverse: '#fff',
  inputBgColorReverse: '#084c8d',
  inputBgColorReverseFocus: '#003d78',
  stroke: '#e8eef4',
  cardBackgroundColor: '#0D5EAC',
  primaryButtonBgColor: '#6cb33e',
  primaryButtonHoverBgColor: '#62a038',
  primaryButtonTextShadow: '0 1px 2px rgba(0,0,0,.65)',
  tripModeBgColor: '#0d5eac',
  dueTextColor: '#4E802D',
  skeletonBaseColor: '#d5d5d5'
};

export const DARK_THEME = {
  ...BASE_THEME,
  name: 'Dark',
  mainColor: '#A6D4FF',
  secondaryColor: '#E0F0FF',
  tertiaryColor: '#595959',
  backgroundColor: '#1f1f1f',
  secondaryBackgroundColor: '#2b2b2b',
  primaryFontColor: '#fff',
  primaryFontColorReverse: '#1f1f1f',
  secondaryFontColor: '#ccc',
  popperFontColor: '#b3b3b3',
  hoverColor: '#333',
  activeColor: '#222',
  hoverColorInverted: '#efefef',
  activeColorInverted: '#dedede',
  focusColorInverted: '#dedede',
  badgeBorderColor: 'transparent',
  successColor: '#007e33',
  actionColor: '#5c97d1',
  borderColor: '#2b2b2b',
  buttonColor1: '#008ab0',
  buttonColor1Hover: '#007101',
  buttonColor2: '#efefef',
  buttonText1: 'white',
  buttonText2: '#555',
  softToastShadowColor: 'rgba(0, 0, 0, 0.5)',
  inverseBackgroundColor1: '#084c8d',
  inverseBackgroundColor2: '#0a559e',
  inverseBackgroundColor3: '#003d78',
  inverseBackgroundColor4: '#002345',
  inverseFontColor1: '#ecf6ff',
  inverseFontColor2: '#A5C0D5',
  inverseButtonColor1: '#3F70A2',
  inverseButtonColor2: '#6896c4',
  inverseButtonColor3: '#86abd0',
  inactiveColor: '#808080',
  inputBgColor: '#084c8d',
  inputBgColorFocus: '#003d78',
  inputBgColorReverse: '#f5f5f5',
  inputBgColorReverseFocus: '#e1e1e1',
  stroke: '#595959',
  cardBackgroundColor: '#0D5EAC',
  tripModeBgColor: '#0d5eac',
  dueTextColor: '#4E802D',
  skeletonBaseColor: '#d5d5d5'
};
