export const LANGUAGE_OPTIONS = [
  {
    name: 'English',
    display_name: 'English',
    symbol: 'en',
    smartlingTranslationAvailable: true
  },
  {
    name: 'Spanish',
    display_name: 'Español',
    symbol: 'es',
    smartlingTranslationAvailable: true
  },
  {
    name: 'Vietnamese',
    display_name: 'TiếngViệt',
    symbol: 'vi',
    smartlingTranslationAvailable: false
  },
  {
    name: 'Korean',
    display_name: '한국어',
    symbol: 'ko',
    smartlingTranslationAvailable: false
  },
  {
    name: 'Russian',
    display_name: 'РУССКИЙ',
    symbol: 'ru',
    smartlingTranslationAvailable: false
  },
  {
    name: 'Ukranian',
    display_name: 'Українськи',
    symbol: 'uk',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/uk/index.htm'
  },

  {
    name: 'Chinese',
    display_name: '中文',
    symbol: 'zh',
    smartlingTranslationAvailable: false
  },
  {
    name: 'Japanese',
    display_name: '日本語',
    symbol: 'ja',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/ja/index.htm'
  },
  {
    name: 'Romanian',
    display_name: 'Română',
    symbol: 'ro',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/ro/index.htm'
  },
  {
    name: 'Tagalog',
    display_name: 'Tagalog',
    symbol: 'tl',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/tl/index.htm'
  },
  {
    name: 'Khmer',
    display_name: 'ឈ្មោះភាសា',
    symbol: 'km',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/km/index.htm'
  },
  {
    name: 'Somalian',
    display_name: 'Somali',
    symbol: 'so',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/so/index.htm'
  },
  {
    name: 'Arabic',
    display_name: 'اللغة العربية',
    symbol: 'ar',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/ar/index.htm'
  },
  {
    name: 'Farsi',
    display_name: 'فارسی',
    symbol: 'fa',
    smartlingTranslationAvailable: false,
    staticUrl: 'https://trimet.org/fa/index.htm'
  }
];
export const SERVICE_STATE = 'Oregon';

export const DEFAULT_FONT_FAMILY =
  'Source Sans Pro, Helvetica, Arial, sans-serif';

// Route config constants
export const DEFAULT_ROUTE_COLOR = '#084c8d';
export const MAX_SHUTTLE_COLOR = '#fff';

// Maximum width of the content
export const MAX_CONTENT_WIDTH_NUMBER = 1920;
export const MAX_CONTENT_WIDTH = `${MAX_CONTENT_WIDTH_NUMBER}px`;

// Extra extra small devices: Usually older mobile devices
const XXSPHONE_BREAK_NUMBER = 380;
export const XXSPHONE_BREAK = `${XXSPHONE_BREAK_NUMBER}px`;

// Extra small devices: Smaller mobile devices
const XSPHONE_BREAK_NUMBER = 480;
export const XSPHONE_BREAK = `${XSPHONE_BREAK_NUMBER}px`;

// Small devices: larger mobile, small tablets
const PHONE_BREAK_NUMBER = 576;
export const PHONE_BREAK = `${PHONE_BREAK_NUMBER}px`;

// Medium devices: Most tablets portrait, smaller desktops
const TABLET_BREAK_NUMBER = 768;
export const TABLET_BREAK = `${TABLET_BREAK_NUMBER}px`;

// Large devices: Desktops, tablet landscapes, ...
export const DESKTOP_MIN_BREAK_NUMBER = 992;
export const DESKTOP_MIN_BREAK = `${DESKTOP_MIN_BREAK_NUMBER}px`;

// Catch all mobile devices MAX breakpoint
export const MOBILE_MAX_BREAK_NUMBER = DESKTOP_MIN_BREAK_NUMBER - 1;
export const MOBILE_MAX_BREAK = `${MOBILE_MAX_BREAK_NUMBER}px`;

const XLDESKTOP_BREAK_NUMBER = 1200;
export const XLDESKTOP_BREAK = `${XLDESKTOP_BREAK_NUMBER}px`;

// Side panel is displayed at DESKTOP_MIN_BREAK
export const SIDE_PANEL_MIN_WIDTH_NUMBER = 390;
export const SIDE_PANEL_MIN_WIDTH = `${SIDE_PANEL_MIN_WIDTH_NUMBER}px`;
export const SIDE_PANEL_MAX_WIDTH_NUMBER = 500;
export const SIDE_PANEL_MAX_WIDTH = `${SIDE_PANEL_MAX_WIDTH_NUMBER}px`;
export const SIDE_PANEL_OFFSET_DECIMAL = 0.02;
export const SIDE_PANEL_OFFSET = `${SIDE_PANEL_OFFSET_DECIMAL * 100}%`;
export const SIDE_PANEL_WIDTH_DECIMAL = 0.36;
export const SIDE_PANEL_WIDTH = `${SIDE_PANEL_WIDTH_DECIMAL * 100}%`;
export const SIDE_PANEL_DURATION_VALUE = 300;
export const SIDE_PANEL_DURATION = `${SIDE_PANEL_DURATION_VALUE}ms`;

export const RECENT_SEARCH_RESULTS_LIMIT = 10;
export const GOV_DELIVERY_ROUTE =
  'https://public.govdelivery.com/accounts/ORTRIMET/subscribers/qualify';

export const ORIGIN_LOCATION_TYPE = 'from';
export const DESTINATION_LOCATION_TYPE = 'to';
export const CURRENT_LOCATION_TYPE = 'current';
export const TIME_SELECTOR = 'time';
export const DATE_SELECTOR = 'date';
export const DEPARTURE_OPTION_SELECTOR = 'departArrive';
export const ARRIVE_BY_SELECTOR = 'arriveBy';
export const MODE_SELECTOR = 'mode';
export const DEPART_AT_OPTION = 'DEPART';
export const ARRIVE_BY_OPTION = 'ARRIVE';
export const DEPART_NOW_OPTION = 'NOW';

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

// storage keys
export const PRINT_TRIP_STORAGE_KEY = 'printTripData';
export const MANUAL_LOCATION_STORAGE_KEY = 'manualLocation';
export const GEOLOCATION_APPROVED_STORAGE_KEY = 'geolocationApproved';
export const RECENT_SEARCHES_STORAGE_KEY = 'omnibox:recentsearches';
export const BASE_LAYER_STORAGE_KEY = 'baseLayer';
export const CHECK_STORAGE_KEY = 'storageCheck';
export const ALERT_TIMESTAMP_KEY = 'alertTimestamp';
export const ALERT_DISMISSED_KEY = 'alertDismissed';
export const UI_LANGUAGE_KEY = 'uiLanguage';
export const SHOW_LANGUAGE_CHANGE_TOAST = 'showLanguageChangeToast';

export const OTP_UI_CURRENT_LOCATION_TYPE = 'CURRENT_LOCATION';

export const TRANSLATE_LIST_BUTTON_VIEWBOX = '0 0 66.653 64.852';
export const SOFT_TOAST_LANGUAGE_ICON_VIEWBOX = '0 0 125 50';

export const Z_INDEX_499 = 499;
export const Z_INDEX_500 = 500;
export const Z_INDEX_600 = 600;
export const Z_INDEX_1000 = 1000;
export const Z_INDEX_TOP = 9999;
export const Z_INDEX_SECOND_TOP = Z_INDEX_TOP - 1;
export const Z_INDEX_THIRD_TOP = Z_INDEX_SECOND_TOP - 1;
export const NAV_HEIGHT_VALUE = 50;
export const NAV_HEIGHT = `${NAV_HEIGHT_VALUE}px`;
export const NAV_PADDING_TOP_VALUE = 15;
export const NAV_PADDING_TOP = `${NAV_PADDING_TOP_VALUE}px`;
export const NAV_OFFSET_DETAIL_VIEW = `${
  NAV_PADDING_TOP_VALUE + NAV_HEIGHT_VALUE
}px`;
export const DETAIL_HEADER_HEIGHT = `${NAV_HEIGHT_VALUE}px`;
export const SYSTEM_ALERT_BANNER_HEIGHT_VALUE = 100;
export const SYSTEM_ALERT_BANNER_HEIGHT = `${SYSTEM_ALERT_BANNER_HEIGHT_VALUE}px`;
export const SYSTEM_PLUS_NAV_VALUE =
  SYSTEM_ALERT_BANNER_HEIGHT_VALUE + NAV_HEIGHT_VALUE;
export const SYSTEM_PLUS_NAV = `${SYSTEM_PLUS_NAV_VALUE}px`;
export const TOP_BORDER_RADIUS = `10px 10px 0 0`;
export const DETAILNAV_BUTTON_VALUE = 32;
export const DETAILNAV_BUTTON_WIDTH = `${DETAILNAV_BUTTON_VALUE}px`;
export const DETAILNAV_BUTTON_HEIGHT = `${DETAILNAV_BUTTON_VALUE}px`;
export const LEAFLET_ZOOM_PADDING_BOTTOM = `136px`;

export const AERIAL_TRAM_MODE = 'GONDOLA';
export const BICYCLE_MODE = 'BICYCLE';
export const BICYCLE_RENT_MODE = 'BICYCLE_RENT';
export const BUS_MODE = 'BUS';
export const BUS_FX_MODE = 'BUS_FX';
export const CAR_MODE = 'CAR';
export const CAR_HAIL_MODE = 'CAR_HAIL';
export const CAR_RENT_MODE = 'CAR_RENT';
export const FERRY_MODE = 'FERRY';
export const LIGHT_RAIL_MODE = 'TRAM';
export const MAIN_TRANSIT_MODE = 'TRANSIT';
export const MICROMOBILITY_MODE = 'MICROMOBILITY';
export const MICROMOBILITY_RENT_MODE = 'MICROMOBILITY_RENT';
export const PARK_AND_RIDE_MODE = 'CAR_PARK';
export const RAIL_MODE = 'RAIL';
export const SUBWAY_MODE = 'SUBWAY';
export const WALK_MODE = 'WALK';

export const BIKE_SHARE_VERTEX_TYPE = 'BIKESHARE';
export const SCOOTER_CATEGORY_ID = 'SCOOTER';

// train codes
export const TRAIN_ALL_CODES = [90, 100, 190, 200, 290, 203, 193, 194, 195];

// streetcar routes, and the GovDelivery topic they correspond to:
export const STREETCAR_ROUTES = ['193', '194', '195'];
export const STREETCAR_GOV_DELIVERY_TOPIC = '193';

// the following are not distinct modes within OpenTripPlanner, but are
// needed within this application
export const PARATRANSIT_MODE = 'LIFT';
export const STREETCAR_MODE = 'STREETCAR';

export const MICROMOBILITY_STARTING_TYPE = 'VEHICLERENTAL';
export const PORTLAND_STREETCAR_AGENCY = 'PSC';

export const MIN_STOP_LAYER_ZOOM = 16;
export const MIN_ADV_STOP_LAYER_ZOOM = 18;

export const GTM_DATA_ATTRIBUTE = 'data-gtm';
export const STORAGE_PREFIX = 'trimet';

export const AERIAL_TRAM_LINK = 'https://www.gobytram.com';
export const SECURITY_LINK = 'https://go.elerts.com/trimet?ref=HOMEPAGE';
export const HOP_HOME_LINK = 'https://myhopcard.com/home/';
export const HOP_PAGE_LINKS = Object.entries({
  phone: `pay-with-your-phone`,
  hopCard: `get-card`,
  contactless: `tap-and-go`
}).reduce(
  (obj, [pageKey, pagePath]) => ({
    ...obj,
    [pageKey]: HOP_HOME_LINK + pagePath
  }),
  {}
);

export const COOKIES_HELP_LINK =
  'https://www.wikihow.com/Enable-Cookies-in-Your-Internet-Web-Browser#';

export const ARRIVALS_REFRESH_INTERVAL = 5000;
export const GEOLOCATION_REPOLL_INTERVAL = 15000;
export const FEET_IN_A_MILE = 5280;

export const SOFT_TOAST_INTERVAL = 30000;
export const THROTTLE_WINDOW = 4000;

export const ENGLISH_LOCALE_CODE = 'en';
export const CHINESE_LOCALE_CODE = 'zh';

export const TRIMET_TIME_FORMAT = 'h:mmaaa';
export const TRIMET_DATE_FORMAT = 'MMM dd, yyyy';
export const TRIMET_LONG_DATE_FORMAT = 'MMMM dd, yyyy';
